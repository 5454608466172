<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()" id="listTemplates" :showAdder="true" @add="add()"/>

        <Form v-if="editor"
              :formValues="formValues"
              @values="handleValues"
              @close="close()"
              @submit="storeTemplate"/>

        <table>
            <thead>
            <tr>
                <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="name" caption="Name in der App" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="countCompetences" caption="# Teilkompetenzen" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="countCompetences" caption="# Kompetenzen" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="datetime_created" caption="erstellt" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="datetime_updated" caption="aktualisiert" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="template in templates" :key="'row_'+template.id" class="clickable" @click="open(template)">
                <td><strong>{{ template.id }}</strong></td>
                <td><strong>{{ template.name }}</strong></td>
                <td>{{ template.categoryCount }}</td>
                <td>{{ template.competenceCount }}</td>
                <td>{{ $core.getReformatter().formatTimestamp( template.datetimeCreated ) }}</td>
                <td>{{ $core.getReformatter().formatTimestamp( template.datetimeUpdated ) }}</td>
                <td>
                    <div class="button edit" :id="'subs_'+template.id" @click="handleEditSubCompetences">K</div>
                    <div class="button cancel" :id="'delete_'+template.id" @click="handleDelete">X</div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Kompetenzen-Vorlagen werden abgerufen..."/>
</template>

<script>

import ElementFilter  from '@/components/elements/ElementFilter'
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import Form           from '@/components/elements/fullscreen/Form'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    name      : 'ToolsCompetenceTemplates',
    components: { Form, Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],

    data()
    {
        return {
            ready     : false,
            editor    : false,
            listTypes : [
                'checklist', 'ratinglist', 'customFixed', 'recallList', 'test'
            ],
            formValues: {},
            sortBy    : 'identifier',
            sortDir   : 'ascending',
            templates : [],
            mode      : false
        }
    },

    created()
    {
        this.init()
    },

    methods: {

        init()
        {

            this.templates = []
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_listCompetenceTemplates'
                } )
                .then( response =>
                {

                    let list = response.templates
                    for( let l in list )
                    {
                        this.templates.push( list[ l ] )
                    }
                    this.sort()
                } )
                .catch( () =>
                {
                    this.ready = true
                } )

        },

        _fetchTemplate( id )
        {
            return new Promise( ( resolve, reject ) =>
            {

                this.$core.getClient()
                    .request( {
                        method    : 'admin.tools_getCompetenceTemplate',
                        templateId: id
                    } )
                    .then( response =>
                    {
                        return resolve( response )
                    } )
                    .catch( () =>
                    {
                        return reject()
                    } )

            } )
        },

        open( tpl )
        {

            this.mode = 'edit'
            this._fetchTemplate( tpl.id )
                .then( remoteTemplate =>
                {

                    let template       = remoteTemplate.template.template,
                        subCompetences = remoteTemplate.template.categories

                    this.formValues = {
                        title : 'Kompetenz-Template <strong>' + template.name + '</strong> bearbeiten',
                        fields: [
                            {
                                caption: 'id',
                                type   : 'hidden',
                                id     : 'template_id',
                                value  : tpl.id
                            },
                            {
                                caption: 'localId',
                                type   : 'hidden',
                                id     : 'localId',
                                value  : template.localId
                            },
                            {
                                caption: 'Identifier (automatisch)',
                                type   : 'hidden',
                                id     : 'identifier',
                                value  : template.identifier
                            },
                            {
                                caption: 'Name in der App',
                                type   : 'TextBox',
                                id     : 'name',
                                value  : template.name,
                                hook   : {
                                    id  : 'identifier',
                                    type: 'cleanId'
                                }
                            },
                            {
                                caption: 'Beschreibung',
                                type   : 'TextArea',
                                class  : 'small',
                                id     : 'description',
                                value  : template.description,
                            },
                            {
                                caption: 'Teilkompetenzen',
                                type   : 'RowAdder',
                                rowType: 'subCompetence',
                                setup  : {
                                    title : {
                                        key: 'caption'
                                    },
                                    text  : {
                                        key: 'description'
                                    },
                                    hidden: {
                                        contains  : true,
                                        idTemplate: template.id,
                                        identifier: {
                                            type  : 'localId',
                                            source: null
                                        }
                                    },
                                    adder : {
                                        caption   : '',
                                        columnType: '',
                                        id        : '',
                                        idTemplate: template.id,
                                        identifier: ''
                                    }
                                },
                                id     : 'subCompetences',
                                value  : subCompetences
                            },
                            {
                                type : 'hidden',
                                id   : 'id_author',
                                value: this.$store.getters.idUser
                            },
                            {
                                type : 'hidden',
                                id   : 'mode',
                                value: 'edit'
                            }
                        ]
                    }

                    this.editor = true

                } )

        },

        add()
        {

            this.mode = 'add'
            this.formValues = {
                title : '<strong>Neues</strong> Kompetenz-Template anlegen',
                fields: [
                    {
                        caption: '',
                        text   : '<strong>Lege hier</strong> die Kompetenzbereiche mit ihren Teilkompetenzen an. Die Kompetenzen, die zu den Teilkompetenzen gehören sollen, kannst du später bearbeiten.',
                        type   : 'Paragraph',
                        id     : null,
                        value  : null
                    },
                    {
                        caption: 'Identifier (automatisch)',
                        type   : 'hidden',
                        id     : 'identifier',
                        value  : ''
                    },
                    {
                        caption: 'LocalId',
                        type   : 'hidden',
                        id     : 'localId',
                        value  : this.$core.uuid().generate()
                    },
                    {
                        caption: 'Name in der App',
                        type   : 'TextBox',
                        id     : 'name',
                        value  : '',
                        hook   : {
                            id  : 'identifier',
                            type: 'cleanId'
                        }
                    },
                    {
                        caption: 'Beschreibung',
                        type   : 'TextArea',
                        class  : 'small',
                        id     : 'description',
                        value  : '',
                    },
                    {
                        caption: 'Teilkompetenzen',
                        type   : 'RowAdder',
                        rowType: 'subCompetence',
                        setup  : {
                            title : {
                                key: 'caption'
                            },
                            text  : {
                                key: 'description'
                            },
                            hidden: {
                                contains  : true,
                                identifier: {
                                    type  : 'localId',
                                    source: null
                                }
                            },
                            adder : {
                                caption   : '',
                                columnType: '',
                                id        : '',
                                identifier: ''
                            }
                        },
                        id     : 'subCompetences',
                        value  : [
                            'create'
                        ]
                    },
                    {
                        type : 'hidden',
                        id   : 'id_author',
                        value: this.$store.getters.idUser
                    },
                    {
                        type : 'hidden',
                        id   : 'mode',
                        value: 'add'
                    }
                ]
            }

            this.editor = true

        },

        storeTemplate( values )
        {

            if( 'add' === values.mode )
            {
                values.id = 'create'
            }

            console.log( '>>>', values )

            let messageTitle,
                idTemplate,
                method,
                subs = []

            switch( this.mode )
            {
                case 'add':
                case 'edit':
                    method = 'admin.tools_' + ( this.mode === 'add' ? 'store' : 'edit' ) + 'CompetenceTemplate'
                    messageTitle = '<strong>Vorlage ' + ( this.mode === 'add' ? 'gespeichert' : 'aktualisiert' ) + '</strong>'
                    break
                case 'competences':
                    method = 'admin.tools_storeCompetenceTemplateCompetences'
                    messageTitle = '<strong>Kompetenzen aktualisiert</strong>'
                    idTemplate = parseInt( values.idTemplate )

                    for( let v in values )
                    {
                        let sub = {}
                        if( 'sub_' === v.substring( 0, 4 ) )
                        {
                            let id = v.split( /_/ ).pop()
                            sub.id = id
                            sub.competences = JSON.parse( values[ v ] )
                            subs.push( sub )
                        }
                    }

                    values = subs
                    break
            }

            let request = {
                method    : method,
                idTemplate: idTemplate,
                setup     : values
            }

            console.log( 'STORE', request )

            this.$core.getClient()
                            .request( request )
                            .then( response =>
                            {

                                this.editor = false
                                this.$core.f().uiMessage( 'success', messageTitle )
                                this.init()

                            } )

        },

        deleteTemplate( id )
        {
            this.$core.getClient()
                .request( {
                    method    : 'admin.tools_deleteCompetenceTemplate',
                    templateId: id
                } )
                .then( () =>
                {
                    this.$core.f().uiMessage( 'success', '<strong>Vorlage gelöscht</strong>' )
                    this.init()
                } )
                .catch( () =>
                {
                    this.$core.f().uiMessage( 'error', '<strong>Vorlage konnte nicht gelöscht werden</strong>' )
                } )
        },

        handleDelete( event )
        {

            event.preventDefault()
            event.stopPropagation()

            let id = event.target.id.replace( 'delete_', '' )

            let setup = {
                show   : true,
                caption: 'Template ' + id + ' löschen',
                buttons: {
                    'left' : {
                        class   : 'default',
                        caption : 'lieber nicht',
                        callback: () =>
                        {
                            console.log( 'ney' )
                        }
                    },
                    'right': {
                        class   : 'delete',
                        caption : 'löschen',
                        callback: () =>
                        {
                            this.deleteTemplate( id )
                        }
                    }
                },
                message: '<strong>Bist du sicher</strong>, dass die Kompetenzen-Vorlage gelöscht werden soll?'
            }

            this.$core.getEventManager().dispatch( 'show-modal-dialog', setup )

        },

        close()
        {
            this.formValues = {}
            this.editor = false
        },

        sort()
        {
            this.templates = this.$core.getSorter().sortObjects( this.templates, this.sortBy, this.sortDir )
            this.ready = true
        },

        handleRefresh()
        {
            this.init()
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortTemplates', args.id )
            this.$store.commit( 'setSortTemplatesDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        },

        handleEditSubCompetences( event )
        {

            this.mode = 'competences'
            this.$core.f().skip( event )
            let id = parseInt( event.target.id.split( '_' ).pop() )
            this._fetchTemplate( id )
                .then( remoteTemplate =>
                {

                    let template       = remoteTemplate.template.template,
                        subCompetences = remoteTemplate.template.categories,
                        competences    = remoteTemplate.template.competences

                    this.formValues = {
                        title : 'Kompetenzen für <strong>' + template.name + '</strong> bearbeiten',
                        fields: [
                            {
                                caption: 'id',
                                type   : 'hidden',
                                id     : 'idTemplate',
                                value  : parseInt( id )
                            },
                            {
                                caption: 'localId',
                                type   : 'hidden',
                                id     : 'localId',
                                value  : template.localId
                            } ]
                    }

                    let mainSubs = []

                    for( let c in competences )
                    {
                        if( competences[ c ].id_category === 0 )
                        {
                            mainSubs.push( competences[ c ] )
                        }
                    }

                    this.formValues.fields.push( {
                        caption: 'Kompetenzbereich "' + template.name + '"',
                        text   : 'Hier die Kompetenzen anlegen, die direkt im Kompetenzbereich liegen',
                        type   : 'Paragraph',
                        id     : null,
                        value  : null
                    } )

                    this.formValues.fields.push( {
                        caption: '',
                        type   : 'OpenableSubCompetence',
                        parent : template.name,
                        id     : 'sub_0',
                        value  : mainSubs
                    } )

                    for( let s in subCompetences )
                    {

                        this.formValues.fields.push( {
                            caption: subCompetences[ s ].title,
                            text   : subCompetences[ s ].description,
                            type   : 'Paragraph',
                            id     : null,
                            value  : null
                        } )

                        let subSubs = []

                        for( let c in competences )
                        {
                            if( competences[ c ].id_category === subCompetences[ s ].id )
                            {
                                subSubs.push( competences[ c ] )
                            }
                        }

                        this.formValues.fields.push( {
                            caption: '',
                            type   : 'OpenableSubCompetence',
                            parent : template.name,
                            id     : 'sub_' + subCompetences[ s ].id,
                            value  : subSubs
                        } )

                    }

                    this.editor = true

                } )


        },

        handleValues( values )
        {
            console.log( 'HDL VALUES >>> DBG', values )
        }

    }

}
</script>