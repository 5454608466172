export default class Config
{

    constructor()
    {

        if( !Config.instance )
        {

            this.setConfig()
            Config.instance = this

        }

        return Config.instance

    }

    setConfig()
    {

        this.versionNumber = '2.5.2'
        this.build = process.env.VERSION
        this.branch = process.env.BRANCH
        this.commitHash = process.env.COMMIT_HASH
        this.version = this.versionNumber + '-' + this.branch + ' (' + this.build + ')'
        this.baseUrl = 'http://localhost/'
        this.verbosity = 99
        this.server = 'test2.entzettelt.de'
        this.endpoint = 'cwss'
        this.port = '3403'
        this.verbosity = 9
        this.socketUrl = 'wss://' + this.server + '/' + this.endpoint + '/'

        this.serverPublicKey = 'IsBMhBYWq4BIVeDPdcrYqSr3qWCC0yQoVdLMlshytQQ='

        this.roles = {
            1 : {
                role      : 'helpdesk',
                routes    : [ 'dashboard', 'helpdesk', 'tools', 'monitoring', 'admin' ],
                functions : [],
                restricted: [ 'tools/server', 'tools/toolkit', 'tools/newsletter' ],
                flags     : [ 'adminOnlySelf' ]
            },
            5 : {
                role      : 'editor',
                routes    : [ 'dashboard', 'tools', 'monitoring', 'admin' ],
                functions : [],
                restricted: [],
                flags     : [ 'adminOnlySelf' ]
            },
/*            10 : {
                role      : 'administrator',
                routes    : [ 'dashboard', 'helpdesk', 'tools', 'monitoring', 'admin' ],
                functions : [],
                restricted: [ 'tools/server', 'tools/toolkit', 'tools/newsletter' ],
                flags     : [ 'adminOnlySelf' ]
            }*/
            10: {
                role      : 'administrator',
                isWildcard: true
            }
        }

        Config.instance = this

    }

    getBuild()
    {
        return this.build
    }

    refresh()
    {
        this.setConfig()
    }

    destruct()
    {
        delete Config.instance
    }

}